import React, { useContext } from "react"
import classNames from "classnames"
// import { Field, ErrorMessage } from "formik"
// import UploadDocuments from "../../UploadDocuments/UploadDocuments"
import Dropzone from "../../Elements/Dropzone"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faPrescription } from "@fortawesome/free-solid-svg-icons"
// import UploadDocumentDropzone from "../../UploadDocuments/UploadDocumentDropzone"
import { AppContext } from "../../../context/AppContext"

const MAX_FILE_SIZE_IN_BYTES = 8388608
const MAX_FILE_COUNT = 1

const FormFileupload = ({
  label,
  isRequired,
  placeholder,
  children,
  name,
  rows,
  value,
  readOnly,
  maxLength,
  labelIconColor,
  labelIcon,
  hideOptional,
  helper,
  helperClassName,
}) => {
  const { state, dispatch } = useContext(AppContext)
  const { documents } = state
  let files = []
  const handleUploadSuccess = ({ file }) => {
    files.push(file)
    dispatch({
      type: "SAVE_DOCUMENTS",
      payload: files,
    })
  }

  return (
    <div className="field mb-1 mt-1">
      <label className={classNames("label has-text-weight-normal")}>
        {!!labelIcon && (
          <span className={`icon has-text-${labelIconColor}`}>{labelIcon}</span>
        )}
        {label}
        {!isRequired && !hideOptional && (
          <span className="has-text-grey is-italic"> (Optional)</span>
        )}
        {!!helper && (
          <span
            className={classNames(
              "help has-text-weight-normal",
              helperClassName
            )}
          >
            {helper}
          </span>
        )}
      </label>
      <Dropzone
        docType="rx"
        label="Upload Prescription"
        icon="prescription"
        maxFileCount={MAX_FILE_COUNT}
        maxFileSize={MAX_FILE_SIZE_IN_BYTES}
        currentFilesList={documents}
        onUploadSuccess={handleUploadSuccess}
      />
      {/* <Dropzone
      maxByteSize={MAX_FILE_SIZE_IN_BYTES}
      maxFileCount={MAX_FILE_COUNT}
      docType="RX"
      value={value}
      name={name}
    >
      <FontAwesomeIcon icon={faPrescription} className="mb-1" size="2x" />
      <br />
      <small>{label}</small>
    </Dropzone> */}
      {/* <div class="file has-name is-fullwidth">
      <label class="file-label">
        <input class="file-input" type="file" name={name} value={value} />
        <span class="file-cta">
          <span class="file-icon">
            <FontAwesomeIcon icon={faUpload} />
          </span>
          <span class="file-label">Choose a file</span>
        </span>
        <span class="file-name">No file chosen</span>
      </label>
    </div> */}
      {/* <label className={classNames("label has-text-weight-normal")}>
      {!!labelIcon && (
        <span className={`icon has-text-${labelIconColor}`}>{labelIcon}</span>
      )}
      {label}
      {!isRequired && !hideOptional && (
        <span className="has-text-grey is-italic"> (Optional)</span>
      )}
      {!!helper && (
        <span
          className={classNames("help has-text-weight-normal", helperClassName)}
        >
          {helper}
        </span>
      )}
    </label>
    <div className="field-body">
      <div className="field">
        <p className="control">
          <Field name={name}>
            {({ field }) => (
              <textarea
                className="textarea has-fixed-size"
                placeholder={placeholder}
                name={name}
                rows={rows}
                value={value}
                {...field}
                readOnly={readOnly}
                maxLength={maxLength || 250}
              >
                {children}
              </textarea>
            )}
          </Field>
        </p>
        <ErrorMessage name={name}>
          {(error) => <div className="has-text-danger is-size-7">{error}</div>}
        </ErrorMessage>
      </div>
    </div> */}
    </div>
  )
}

export default FormFileupload
