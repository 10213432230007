import React, { useState, useContext } from "react"
// import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"
import { Formik, Form } from "formik"
import classNames from "classnames"

import FormInput from "elements/Form/FormInput"
import FormTextArea from "../../Elements/Form/FormTextarea"
// import FormSelect from "../../Elements/Form/FormSelect"
import FormFileupload from "../../Elements/Form/FormFileupload"
// import subjects from "../utils/emailSubjects.json"
import {
  orderFormValidationSchema,
  orderFormInitialValues,
} from "../utils/formData"
import { initialState } from "../../../context/AppContext"
import { sendInquiry } from "../services/inquiry"
import { AppContext } from "../../../context/AppContext"

const OrderForm = () => {
  const [loading, setLoading] = useState(false)
  // const [emailSubjects, setEmailSubjects] = useState([])
  const [notification, setNotification] = useState({
    message: "",
    active: false,
  })

  const { state, dispatch } = useContext(AppContext)
  const { orderForm, documents } = state
  const { prescriptionFiles } = orderForm
  // useEffect(() => {
  //   setEmailSubjects(subjects.map((item) => ({ value: item, label: item })))
  // }, [])

  // useEffect(() => {
  //   if (notification.active)
  //     setTimeout(() => {
  //       closeNotification()
  //     }, 5000)
  // }, [notification])

  const closeNotification = () => {
    setNotification({ message: "", active: false, type: "" })
  }

  const handleSubmit = (values, { resetForm }) => {
    setLoading(true)
    dispatch({
      type: "SAVE_ORDER_FORM",
      payload: values,
    })
    // dispatch({
    //   type: "SAVE_PRESCRIPTION_FILES",
    //   payload: prescriptionFiles,
    // })
    sendInquiry(
      documents,
      values,
      () => {
        resetForm()
        setLoading(false)
        setNotification({
          active: true,
          type: "success",
          message:
            "Thank you for your inquiry! We'll get back to you within 24 hours.",
        })
      },
      () => {
        setLoading(false)
        setNotification({
          active: true,
          type: "danger",
          message:
            "Something went wrong with sending your email. Please try again.",
        })
      }
    )
  }

  return (
    <div>
      <section id="order-form">
        <h2>Order Form</h2>
        <hr />
        {/* <div className="message">
          <div className="message-body">
            If you are experiencing issues or have questions not found in our{" "}
            <Link
              to="/help-center"
              className="has-text-weight-bold has-text-primary"
            >
              Help Center
            </Link>
            , let us know by filling out the form below.
          </div>
        </div> */}

        <Formik
          initialValues={initialState}
          onSubmit={handleSubmit}
          validationSchema={orderFormValidationSchema}
        >
          {({ values }) => (
            <Form autoComplete="off">
              <FormInput
                name="fullName"
                placeholder="Juana Dela Cruz"
                value={values.fullName}
                label="Full Name"
                isRequired
              />
              <FormInput
                name="email"
                placeholder="jdelacruz@mail.com"
                inputmode="email"
                value={values.email}
                label="Email"
                isRequired
              />

              <FormTextArea
                name="emailBody"
                value={values.emailBody}
                label="Message"
                isRequired
              />
              <FormFileupload
                name="prescriptionFile"
                label="Upload Prescription"
                isRequired
              />
              {notification.active && (
                <div className={`notification is-${notification.type}`}>
                  <button
                    className="delete"
                    type="button"
                    onClick={closeNotification}
                  />
                  {notification.message}
                </div>
              )}
              <button
                type="submit"
                className={classNames(
                  "button is-fullwidth is-primary has-text-centered",
                  {
                    "is-loading": loading,
                  }
                )}
              >
                Send
              </button>
            </Form>
          )}
        </Formik>
      </section>
    </div>
  )
}

export default OrderForm
